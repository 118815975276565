@import 'antd/dist/antd.css';
/* thinnest */
@font-face {
  font-family: 'Campton-Light';
  src:
    local('Campton-Light'),
    url('./fonts/Campton-Light/font.woff') format('woff'),
    url('./fonts/Campton-Light/font.woff2') format('woff2');
}

/* regular */
@font-face {
  font-family: 'Campton-Book';
  src:
    local('Campton-Book'),
    url('./fonts/Campton-Book/font.woff') format('woff'),
    url('./fonts/Campton-Book/font.woff2') format('woff2');
}

/* medium */
@font-face {
  font-family: 'Campton-Medium';
  src:
    local('Campton-Medium'),
    url('./fonts/Campton-Medium/font.woff') format('woff'),
    url('./fonts/Campton-Medium/font.woff2') format('woff2');
}

/* POPPINS */
@font-face {
  font-family: 'Poppins-Regular';
  src:
    local('Poppins'),
    url('./fonts/Poppins/Poppins-Regular.woff') format('woff'),
    url('./fonts/Poppins/Poppins-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Poppins-Medium';
  src:
    local('Poppins'),
    url('./fonts/Poppins/Poppins-Medium.woff') format('woff'),
    url('./fonts/Poppins/Poppins-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Poppins-Bold';
  src:
    local('Poppins'),
    url('./fonts/Poppins/Poppins-Bold.woff') format('woff'),
    url('./fonts/Poppins/Poppins-Bold.woff2') format('woff2');
}

/* GILROY */
@font-face {
  font-family: 'Gilroy-ExtraBold';
  src:
    local('Gilroy-ExtraBold'),
    url('./fonts/Gilroy/Gilroy-ExtraBold.woff') format('woff'),
    url('./fonts/Gilroy/Gilroy-ExtraBold.woff2') format('woff2');
}

#root {
  font-family: 'Campton-Medium', Verdana, sans-serif;
  background: #f4f4f8;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

html,
body {
  margin: 0;
  /* overflow-x: hidden; // this breaks collab hub pagination */
  overscroll-behavior-y: none;
  overscroll-behavior-x: none;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Bypass buggy react error overlay which appears with lint errors */
/* https://stackoverflow.com/questions/70499543/create-react-app-creates-this-iframe-that-prevents-me-from-clicking-or-editing */
body > iframe {
  pointer-events: none;
}

img {
  max-width: 100%;
  image-rendering: -webkit-optimize-contrast;
}

a {
  color: #027df0;
}

h1,
h2,
h3 {
  font-family: 'Gilroy-ExtraBold', Verdana, sans-serif;
}

a {
  transition: 0.2s ease-in-out;
}

/**** ANTD BUTTONS ****/
button:focus {
  outline: none;
}
button.ant-btn {
  transition: 0.2s ease-in-out;
  text-shadow: none !important;
  box-shadow: none !important;
  font-size: 14px;
  border-width: 1px;
  border-radius: 20px;
}
button.ant-btn:disabled {
  pointer-events: none;
}

/* PRIMARY BUTTON */
.ant-btn.ant-btn-primary {
  background: #23262f;
  color: #fff;
  border-color: #23262f;
}
button.ant-btn.ant-btn-primary:hover,
button.ant-btn.ant-btn-primary:focus {
  background: #027df0;
  color: #fff;
  border-color: #027df0;
}
button.ant-btn.ant-btn-primary:disabled {
  background: #999;
  color: #fff;
  border-color: #999;
  cursor: default;
}
button.ant-btn-dangerous.ant-btn-primary:hover,
button.ant-btn-dangerous.ant-btn-primary:focus {
  background: #f5222d;
  color: #fff;
  border-color: #f5222d;
}

/* SECONDARY & DEFAULT BUTTON */
button.ant-btn.ant-btn-secondary,
button.ant-btn.ant-btn-default {
  background: #fff;
  color: #23262f;
  border-color: #23262f;
}
button.ant-btn.ant-btn-secondary:hover,
button.ant-btn.ant-btn-secondary:focus,
button.ant-btn.ant-btn-default:hover,
button.ant-btn.ant-btn-default:focus {
  background: #fff;
  color: #027df0;
  border-color: #027df0;
}
button.ant-btn.ant-btn-secondary:disabled,
button.ant-btn.ant-btn-default:disabled {
  background: #fff;
  color: #999;
  border-color: #999;
  cursor: default;
}
button.ant-btn-dangerous.ant-btn-secondary:hover,
button.ant-btn-dangerous.ant-btn-secondary:focus {
  background: #fff;
  color: #f5222d;
  border-color: #f5222d;
}

/* LINK/TEXT BUTTON */
button.ant-btn.ant-btn-link,
button.ant-btn.ant-btn-text {
  padding-left: 4px;
  padding-right: 4px;
}
button.ant-btn.ant-btn-link:hover,
button.ant-btn.ant-btn-link:focus,
button.ant-btn.ant-btn-text:hover,
button.ant-btn.ant-btn-text:focus {
  color: #23262f;
  border-color: transparent;
  background-color: transparent;
}
button.ant-btn.ant-btn-link:hover:disabled,
button.ant-btn.ant-btn-link:focus:disabled,
button.ant-btn.ant-btn-text:hover:disabled,
button.ant-btn.ant-btn-text:focus:disabled {
  color: rgba(0, 0, 0, 0.25);
}

/* ANTD FORMS */
.ant-btn-lg,
.ant-input-lg {
  height: 50px !important;
}
.ant-form .ant-input:focus {
  border-color: #027df0;
}
.antd-country-phone-input {
  padding: 0 !important;
}
.ant-form .ant-input::placeholder {
  color: #bbb !important;
}
label,
input,
.ant-menu,
.ant-select-dropdown,
.ant-dropdown-menu,
.ant-modal,
.ant-select,
.ant-picker-dropdown,
.ant-message-custom-content,
.ant-notification {
  font-family: 'Campton-Medium', Verdana, sans-serif;
}
/* fixes password icon overlap with 1password */
.ant-input-password {
  padding-right: 30px !important;
}

.ant-notification-notice {
  border-radius: 10px !important;
}
.ant-notification-notice-description {
  color: #777e90;
}

.ant-alert {
  /* FIXME: remove this styling, sometimes we need no margin */
  margin-bottom: 10px !important;
}

.ant-modal-body {
  padding: 40px 20px !important;
}

/* fixes issue with country phone input dropdown being too wide on mobile (signup) */
.ant-select-dropdown {
  max-width: 250px;
}

/* ANTD DROPDOWN (header profile dropdown) */
.ant-dropdown {
  top: 50px !important;
}
.ant-dropdown-menu-item-group {
  margin: 20px 0;
}
.ant-dropdown-menu-item-group-title {
  background: #f7f7f7;
  border-bottom: 1px solid #ddd;
}

/* ANTD DRAWER */
.ant-drawer-mask {
  cursor: pointer;
}
.ant-drawer.no-padding .ant-drawer-body {
  padding: 0;
}
.ant-drawer .drawer-inner {
  display: flex;
  flex-direction: column;
}
.ant-drawer-content-wrapper {
  font-family: 'Campton-Medium', Verdana, sans-serif;
}
.ant-empty-description {
  color: #999;
}
.ant-drawer-extra {
  flex: 1 !important;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

/* ANTD POPOVER */
.ant-popover {
  font-family: 'Campton-Medium', Verdana, sans-serif;
}
.ant-popconfirm {
  z-index: 1 !important;
}
.ant-popover-buttons {
  display: flex;
  justify-content: flex-end;
}

/* ANTD TABS */
.ant-tabs-nav::before {
  border: none !important;
}

/* CUSTOM GLOBAL STYLES */
.info-text {
  color: #666;
  font-family: 'Campton-Medium', Verdana, sans-serif;
  font-size: 0.8rem;
}
.loading-gradient {
  background-image: linear-gradient(90deg, #f6f6f6 0px, #fafafa 100px, #f6f6f6 300px);
  background-size: 100vw 100%;
  animation: shine 1.5s infinite ease-in-out;
}
@keyframes shine {
  0% {
    background-position-x: -20vw;
  }
  95%,
  100% {
    background-position-x: 85vw;
  }
}
